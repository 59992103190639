import React, { useRef, useState } from 'react';
import { Box, CircularProgress, ClickAwayListener, InputBase, Stack } from '@mui/material';
import MuiTooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { Key } from 'ts-key-enum';
import Minus from '@aph/icons/minusv2.svg';
import Plus from '@aph/icons/plusv2.svg';
import type { TextSize } from '~/styles/text-size';
import { QuantityButton } from '../../product-variant/add-article-to-cart-button/quantity-button';
import type { QuantityButtonProps } from '../../product-variant/add-article-to-cart-button/quantity-button';

type QuantitySelectorProps = {
  quantity: number;
  error?: React.ReactNode;
  fullWidth?: boolean;
  maxQuantity?: number;
  minQuantity?: number;
  disabled?: boolean;
  maxQuantityReason?: React.ReactNode;
  setQuantity?: (quantity: number) => void;
  updateFunctionFromParent: (oldQuantity: number, newQuantity: number) => Promise<void>;
  tempUpdateFunctionFromParent?: (newQuantity: number) => Promise<void>;
  onDismissError?: () => void;
} & Partial<Pick<QuantityButtonProps, 'size'>>;

const widthSizeMapper: Partial<{ [textSize in TextSize]: string }> = {
  small: '90px',
  medium: '122px',
  large: '138px',
};

export const QuantitySelector: React.FC<QuantitySelectorProps> = ({
  quantity,
  fullWidth,
  error,
  maxQuantity = Number.MAX_SAFE_INTEGER,
  minQuantity = 0,
  disabled,
  maxQuantityReason,
  setQuantity,
  updateFunctionFromParent,
  tempUpdateFunctionFromParent,
  onDismissError,
  size = 'medium',
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const [inputFocusValue, setInputFocusValue] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const widthSize = widthSizeMapper[size];

  const handleTooltipClose = () => {
    setTooltipOpen(false);
    onDismissError?.();
  };
  const handleTooltipOpen = () => {
    if (quantity >= maxQuantity) {
      setTooltipOpen(true);
    }
  };

  const pushUpdatedQuantity = (oldQuantity: number, newQuantity = quantity) => {
    if (quantity < minQuantity || !Number.isInteger(newQuantity) || oldQuantity === newQuantity) {
      return;
    }

    const promise1 = updateFunctionFromParent(oldQuantity, Math.min(newQuantity, maxQuantity));
    const promise2 = tempUpdateFunctionFromParent?.(Math.min(newQuantity, maxQuantity));

    setIsLoading(true);
    Promise.allSettled([promise1, promise2])
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleFocus = () => {
    setInputFocusValue(quantity);
    setInputFocus(true);
  };

  const handleBlur = () => {
    pushUpdatedQuantity(quantity, inputFocusValue);
    setQuantity?.(inputFocusValue);
    setInputFocus(false);
  };

  /**
   *
   * @param e Key down event
   * @description Only allow numbers (all keys are numbers) and backspace to be entered into the input field
   */
  const handleOnKey = (e: React.KeyboardEvent) => {
    if (!/^(\d{1,3}|\b)$/.test(e.key)) {
      e.preventDefault();
    }
    if (e.key === Key.Enter) {
      pushUpdatedQuantity(quantity);
      inputRef?.current?.blur();
    }
  };
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(e.currentTarget.value, 10);
    if (newQuantity > maxQuantity) {
      setInputFocusValue(maxQuantity);
      setTooltipOpen(true);
      return;
    }
    setInputFocusValue(Number.isNaN(newQuantity) ? 0 : newQuantity);
  };

  return (
    <Stack
      direction="row"
      bgcolor={theme.palette['color/background/visual']}
      borderRadius="30px"
      minWidth={widthSize}
      width={fullWidth ? '100%' : widthSize}
      p={0.5}
      justifyContent="space-between"
      alignItems="center"
    >
      <QuantityButton
        data-pw="quantity-selector-decrease"
        testId="quantity-selector-decrease"
        isDisabled={Boolean(isLoading || quantity <= minQuantity || disabled)}
        icon={Minus}
        size={size}
        onClick={() => pushUpdatedQuantity(quantity, quantity - 1)}
      />
      {isLoading ? (
        <CircularProgress data-testid="quantity-selector-loading" size="20px" color="primary" />
      ) : (
        <InputBase
          data-pw="quantity-selector-input"
          data-testid="quantity-selector-input"
          disabled={disabled}
          value={inputFocus ? inputFocusValue : quantity}
          onFocus={handleFocus}
          onKeyDown={handleOnKey}
          onChange={handleOnChange}
          onBlur={handleBlur}
          inputRef={inputRef}
          inputProps={{
            style: {
              textAlign: 'center',
              color: theme.palette['color/text/default'],
              margin: '0 2px',
            },
            sx: {
              '&:focus': {
                border: '2px solid',
                boxSizing: 'border-box',
                borderColor: theme.palette['color/border/action'],
                borderRadius: '4px',
              },
            },
          }}
        />
      )}
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <MuiTooltip
          data-pw="quantity-selector-tooltip"
          arrow
          PopperProps={{
            disablePortal: false,
            placement: 'top',
          }}
          onClose={handleTooltipClose}
          open={tooltipOpen || Boolean(error)}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={error || maxQuantityReason || false}
        >
          <Box
            component="span"
            onClick={handleTooltipOpen}
            onKeyDown={handleTooltipOpen}
            tabIndex={0}
            role="button"
          >
            <QuantityButton
              data-pw="quantity-selector-increase"
              testId="quantity-selector-increase"
              isDisabled={Boolean(isLoading || quantity >= maxQuantity || disabled)}
              icon={Plus}
              size={size}
              onClick={() => pushUpdatedQuantity(quantity, quantity + 1)}
            />
          </Box>
        </MuiTooltip>
      </ClickAwayListener>
    </Stack>
  );
};
