// This component is meant for formatting price and label for lineitems and articles.
// TODO: Should be adjusted or removed when design system for typography is set.
import React from 'react';
import { Typography } from '@mui/material';
import type { ColorTokens } from '@aph/ui/types';
import { formatPrice } from '@aph/utilities/format-price/format-price';
import { ICAHeading, ICAText } from '~/styles/fonts';
import type { TextSize } from '~/styles/text-size';
import theme from '~/styles/theme';

export type PriceFormatterProps = {
  price: number;
  strikeThrough?: boolean;
  inline?: boolean;
  size?: TextSize;
  color?: keyof ColorTokens;
};

const priceSizeMapper: { [textSize in TextSize]: string } = {
  xsmall: theme.spacing(1.75), // 14px
  small: theme.spacing(2), // 16px
  medium: theme.spacing(3), // 24px
  large: theme.spacing(3.5), // 28px
  xlarge: theme.spacing(4), // 32px
};

export const PriceFormatter = ({
  price,
  strikeThrough,
  inline = false,
  size = 'medium',
  color = 'color/text/default',
  ...props
}: PriceFormatterProps) => (
  <Typography
    fontSize={priceSizeMapper[size]}
    fontFamily={size === 'xsmall' || size === 'small' ? ICAText : ICAHeading}
    fontWeight={size === 'xsmall' || size === 'small' ? 400 : 700}
    color={color}
    sx={{ textDecoration: strikeThrough ? 'line-through' : 'none' }}
    minWidth={inline ? { md: 100 } : 'auto'}
    {...props}
  >
    {formatPrice(price)}
  </Typography>
);
