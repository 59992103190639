import React, { useEffect } from 'react';
import type { SxProps } from '@mui/material';
import { Stack, SvgIcon } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useInView } from 'react-intersection-observer';
import TickIcon from '@aph/icons/tick.svg';
import { classes as sxs } from './submit-button-with-states.component.sxstyles';

export type SubmitButtonWithStatesProps = {
  sx?: SxProps;
  buttonText: string;
  submitting: boolean | null;
  success: boolean | null;
  successButtonText: string;
  afterSuccessAction?: () => void;
  afterSuccessActionDelay?: number;
  error: { message: string } | null;
};

const propsAreEqual = (
  prevProps: Readonly<React.PropsWithChildren<SubmitButtonWithStatesProps>>,
  nextProps: Readonly<React.PropsWithChildren<SubmitButtonWithStatesProps>>,
) => {
  switch (true) {
    case prevProps.submitting !== nextProps.submitting:
      return false;
    case prevProps.success !== nextProps.success:
      return false;
    case prevProps.error !== nextProps.error:
      return false;
    default:
      return true;
  }
};

export const SubmitButtonWithStates: React.FC<SubmitButtonWithStatesProps> = React.memo(
  ({
    sx,
    buttonText,
    submitting,
    success,
    successButtonText,
    afterSuccessAction,
    afterSuccessActionDelay = process.env
      .NEXT_PUBLIC_DELAY_AFTER_SUCCESSFUL_FORM_SUBMIT as unknown as number,
    error,
  }) => {
    const isDefault = !submitting && !success;
    const isSubmitting = !!submitting;
    const hasSuccess = !!success;
    const hasError = !!error;
    const buttonShouldBeDisabled = !!submitting || !!success || false;

    const {
      ref: alertRef,
      inView,
      entry,
    } = useInView({
      threshold: 1,
      triggerOnce: true,
    });

    useEffect(() => {
      if (hasSuccess && afterSuccessAction) {
        setTimeout(() => {
          afterSuccessAction();
        }, afterSuccessActionDelay);
      }
    }, [afterSuccessAction, afterSuccessActionDelay, hasSuccess]);

    useEffect(() => {
      if (hasError && entry?.target && !inView) {
        entry.target.scrollIntoView();
      }
    }, [hasError]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Stack
        flexDirection="column"
        width="100%"
        justifyContent="flex-start"
        alignItems="center"
        sx={sx}
      >
        <Button
          key="FORM_ELEMENTS.SUBMIT_BUTTON_WITH_STATES.BUTTON"
          id="FORM_ELEMENTS.SUBMIT_BUTTON_WITH_STATES.BUTTON"
          variant="contained"
          color="primary"
          type="submit"
          data-testid="FORM_ELEMENTS.SUBMIT_BUTTON_WITH_STATES.BUTTON"
          disabled={buttonShouldBeDisabled}
          sx={sxs.button}
        >
          {isDefault && buttonText}
          {isSubmitting && (
            <CircularProgress
              data-testid="FORM_ELEMENTS.SUBMIT_BUTTON_WITH_STATES.LOADER"
              size="20px"
              sx={{
                '& circle': {
                  stroke: (theme) => theme.palette['color/icon/inverse-dark'],
                },
              }}
            />
          )}
          {hasSuccess && (
            <>
              {successButtonText}
              <SvgIcon
                width={20}
                sx={{
                  position: 'absolute',
                  right: 16,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  '& path': {
                    fill: (theme) => theme.palette['color/icon/inverse-dark'],
                  },
                }}
              >
                <TickIcon />
              </SvgIcon>
            </>
          )}
        </Button>
        {hasError && (
          <Alert
            sx={sxs.error}
            severity="error"
            color="error"
            variant="filled"
            data-testid="FORM_ELEMENTS.SUBMIT_BUTTON_WITH_STATES.ALERT"
            ref={alertRef}
          >
            {error && error.message}
          </Alert>
        )}
      </Stack>
    );
  },
  propsAreEqual,
);
