import React from 'react';
import Link from 'next/link';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { AphAlertWithButton } from '@aph/components/common/aph-alert/aph-alert-with-button.component';
import ArrowRight from '@aph/icons/arrow_right.svg';
import { Button } from '@aph/ui/components/button/button';
import { ChatEventTopic } from '../chat-event-topic';
import { useChatActions } from '../chat-store/use-chat-store';
import { useChatMediator } from '../use-chat-mediator';
import { classes } from './contact-us-widget-styles';
import { ButtonCircle } from '@aph/ui/components/button-circle/button-circle';

type ContactUsWidgetProps = {
  onToggle: () => void;
  onStartChat: () => void;
  onCookiesClick: () => void;
  isMobile: boolean;
  isOpen: boolean | undefined;
  hasAcceptedCookies: boolean;
};

export const ContactUsWidget: React.FC<ContactUsWidgetProps> = ({
  onToggle,
  onStartChat,
  isMobile,
  isOpen,
  hasAcceptedCookies,
  onCookiesClick,
}) => (
  <Drawer
    open={isOpen}
    onClose={onToggle}
    anchor={isMobile ? 'bottom' : 'right'}
    PaperProps={{
      sx: classes.drawer,
    }}
  >
    <Stack direction="row" justifyContent="flex-end">
      <ButtonCircle icon='Close' onClick={onToggle} />
    </Stack>
    <Content
      hasAcceptedCookies={hasAcceptedCookies}
      onStartChat={onStartChat}
      onCookiesClick={onCookiesClick}
    />
    <Box />
  </Drawer>
);

const Content = ({
  hasAcceptedCookies,
  onStartChat,
  onCookiesClick,
}: {
  hasAcceptedCookies: boolean;
  onStartChat: () => void;
  onCookiesClick: () => void;
}) => {
  const { getIsGiosgAndTeneoReady } = useChatActions();
  const { notify } = useChatMediator();

  const sendTrackingEvent = (option: string) => {
    window.dataLayer.push({ event: 'chat_bot', contact_option: option });
  };

  const startChat = () => {
    sendTrackingEvent('start-chatbot');
    onStartChat();
  };

  const handleOnContactUsClick = () => {
    sendTrackingEvent('contact-us');
    notify(ChatEventTopic.CloseContactUsModal);
  };

  return (
    <Stack gap={3}>
      <Stack gap={1}>
        <Typography variant="h1">
          <FormattedMessage id="CHAT_WIDGET.CONTACT_US.TITLE" />
        </Typography>
        <Typography>
          <FormattedMessage id="CHAT_WIDGET.CONTACT_US.INFO" />
        </Typography>
      </Stack>
      {!hasAcceptedCookies ? (
        <AphAlertWithButton
          severity="info"
          title={<FormattedMessage id="CHAT_WIDGET.CONTACT_US.COOKE_ALERT_TEXT" />}
          button={
            <Button
              data-pw="contact-us-widget-button"
              variant="secondary"
              onClick={onCookiesClick}
              width="full"
            >
              <FormattedMessage id="CHAT_WIDGET.CONTACT_US.COOKE_ALERT_BUTTONTEXT" />
            </Button>
          }
        />
      ) : (
        <Button
          data-pw="contact-us-widget-button"
          isLoading={!getIsGiosgAndTeneoReady()}
          onClick={startChat}
        >
          <FormattedMessage id="CHAT_WIDGET.CONTACT_US.CALL_TO_ACTION.CHAT" />
        </Button>
      )}
      <Divider />
      <Stack direction="row" gap={2}>
        <Link href="/kundservice/kontakta-oss/" onClick={handleOnContactUsClick}>
          <Typography>
            <FormattedMessage id="CHAT_WIDGET.CONTACT_US.LINK_TO_CONTACT_US" />
          </Typography>
        </Link>
        <SvgIcon color="primary">
          <ArrowRight />
        </SvgIcon>
      </Stack>
      <Stack direction="row" gap={2}>
        <Link
          href="https://www.mindoktor.se/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => sendTrackingEvent('min-doktor')}
        >
          <Typography>
            <FormattedMessage id="CHAT_WIDGET.CONTACT_US.MIN_DOKTOR" />
          </Typography>
        </Link>
        <SvgIcon color="primary">
          <ArrowRight />
        </SvgIcon>
      </Stack>
    </Stack>
  );
};
