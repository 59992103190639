export function appendLeadingSlash(str: string) {
  if (str.length > 0 && str.charAt(0) !== '/') {
    return `/${str}`;
  }
  return str;
}

export function appendTrailingSlash(str: string) {
  if (str.length > 0 && str.charAt(str.length - 1) !== '/') {
    return `${str}/`;
  }
  return str;
}

function removeLeadingSlash(str: string) {
  if (str.length > 0 && str.charAt(0) === '/') {
    return str.substring(1);
  }
  return str;
}

function removeTrailingSlash(str: string) {
  if (str.length > 0 && str.charAt(str.length - 1) === '/') {
    return str.substring(0, str.length - 1);
  }
  return str;
}

/**
 * Adds leading and trailing slashes to a string. (e.g. some/url => /some/url/)
 */
export function urlify(urlOrSlug: string | undefined) {
  if (urlOrSlug) {
    return appendTrailingSlash(appendLeadingSlash(urlOrSlug));
  }
  return '';
}

/**
 * Removes leading and trailing slashes from a string. (e.g. /some/url/ => some/url)
 *
 */
export function slugify(urlOrSlug: string | undefined) {
  if (urlOrSlug) {
    return removeLeadingSlash(removeTrailingSlash(urlOrSlug));
  }
  return urlOrSlug;
}

/**
 * Compares two slugs and returns true if they are equal, ignoring leading and trailing slashes.
 */
export function compareSlugs(urlOrSlug1: string, urlOrSlug2: string) {
  const original = slugify(urlOrSlug1);
  const compare = slugify(urlOrSlug2);

  return original === compare;
}
