export const reloadOTBanner = () => {
  const otConsentSdk = document.getElementById('onetrust-consent-sdk');
  if (otConsentSdk) {
    otConsentSdk.remove();
  }

  if (typeof window !== 'undefined' && window.OneTrust != null) {
    setTimeout(() => {
      window.OneTrust.Init();
      setTimeout(() => {
        window.OneTrust.LoadBanner();
      }, 1000);
    }, 1000);
  }
};
