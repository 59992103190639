import React from 'react';
import type { SxProps } from '@mui/material';
import { Stack } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

export const SubmitButtonWithStatesSkeleton: React.FC<{
  sx?: SxProps;
}> = React.memo(
  ({ sx }) => (
    <Stack
      flexDirection="column"
      width="100%"
      justifyContent="flex-start"
      alignItems="center"
      sx={sx}
    >
      <Skeleton
        variant="rectangular"
        height={48}
        sx={{
          borderRadius: 12,
          minWidth: 240,
          maxWidth: '100%',
        }}
      />
    </Stack>
  ),
  () => true,
);
