import theme from '~/styles/theme';

export const classes = {
  button: {
    minWidth: 240,
    maxWidth: '100%',
    minHeight: 40,
    position: 'relative',
    textTransform: 'none',
    borderRadius: theme.spacing(4),
    '& + .error': {
      marginTop: theme.spacing(2),
    },
    '&.buttonStateDisabled.Mui-disabled, &.buttonStateDisabled.Mui-disabled:hover': {
      opacity: '1',
      boxShadow: 'none',
      backgroundColor: theme.palette['color/background/action-dark'],
      color: theme.palette['color/text/inverse-dark'],
    },
  },
  error: {
    width: '100%',
    maxWidth: theme.breakpoints.values.md || 'auto',
  },
};
