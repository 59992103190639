import React from 'react';
import { Box, Typography } from '@mui/material';
import Stars from '@aph/images/voucher-extra-starts.svg';
import { colors, sizes } from './styles';

export type ValueAmountProps = {
  value: number;
  size: 'small' | 'medium'; // add more sizes if needed
  color: 'icaPink' | 'aphGreen' | 'gray' | 'icaRed' | 'orange' | 'blue' | 'darkRed'; // add more colors if needed
  hasIcon: boolean;
};

export const ValueAmount = ({ value, size, color, hasIcon }: ValueAmountProps) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    position="relative"
    borderRadius={1}
    fontSize={sizes[size].fontSize}
    height={sizes[size].height}
    width={sizes[size]}
    bgcolor={colors[color].background}
    color={colors[color].color}
    data-testid="VALUE-AMOUNT"
  >
    {hasIcon && (
      <Box
        component={Stars}
        position="absolute"
        height={57}
        right={-11}
        data-testid="VALUE-AMOUNT-ICON"
      />
    )}
    <Typography variant="largeBonus" fontSize="inherit" color="inherit">
      {value}:-
    </Typography>
  </Box>
);
