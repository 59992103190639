import * as mqs from '../media-queries';
import { values } from './values';

// https://material-ui.com/guides/typescript/#customization-of-theme
declare module '@mui/material/styles' {
  interface Breakpoints {
    mqs: typeof mqs;
  }
}

const breakpoints = {
  values,
  mqs,
};

export default breakpoints;
