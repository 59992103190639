import type { ReactNode } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

type AphAccordionProps = {
  accordionSummary: string;
  accordionDetails: ReactNode;
  id: string;
  /**
   * If handleChange and expandedAccordion are not defined the accordion will use its build in open/close state.
   */
  handleChange?: (id: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  expandedAccordion?: boolean | string;
};

export const AphAccordion: React.FC<AphAccordionProps> = ({
  accordionSummary,
  accordionDetails,
  id,
  handleChange,
  expandedAccordion,
  ...props
}) => {
  return (
    <Accordion
      expanded={handleChange && expandedAccordion === id}
      onChange={handleChange && handleChange(id)}
      disableGutters
      sx={{
        backgroundColor: 'transparent',
        padding: 0,
        boxShadow: 'none',
        borderBottom: (theme) => `1px solid ${theme.palette['color/border/default']}`,
        '&:last-child': {
          borderBottomRightRadius: '0px',
          borderBottomLeftRadius: '0px',
        },
        '&:before': {
          display: 'none',
        },
      }}
      {...props}
    >
      <AccordionSummary
        sx={{ padding: 0, columnGap: 1 }}
        data-pw="aph-accordion-summary"
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`Frequently-asked-questions-${id}-content`}
        id={`Frequently-asked-questions-${id}-header`}
      >
        <Typography variant="normalBold">{accordionSummary}</Typography>
      </AccordionSummary>
      <AccordionDetails data-pw="aph-accordion-details" sx={{ padding: 0 }}>
        {accordionDetails}
      </AccordionDetails>
    </Accordion>
  );
};
